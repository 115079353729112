
@media (min-width: 840px) {
  header{
    // background: rgba(16,69,135,1);
  }
}

.header-popup{
  width: 680px;
}
.popover-container{
  .icon{
    display: none;
  }
  .feature-block{
    margin: 0;
    padding: 0;
    border: none;
    h2{
      margin: 0;
    }
    .is-new, .more, .is-renew{
      display: none;
    }
  }
}

$blue: $link_color;

.navbar {
  padding: 40px 0;
  z-index: 10000;
  .section-heading {
    width: 100%;
    background: white;
    position: fixed;
    overflow: hidden;
    z-index: 999;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    .login {
      margin-top: 3px;
      color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px 12px;
    }
    a {
      margin: 0;
      padding: 16px 16px 7px 16px;
      white-space: nowrap;
      color: $blue;
      &.navbar-brand {
        width: 100%;
        max-width: 180px;
        margin: 0;
        padding: 0;
        height: auto;
        @media (max-width: 768px) {
          max-width: 160px;
          padding-left: 25px;
        }
      }
    }
  }
  nav {
    a {
      font-size: 0.9em;
      font-weight: bold;
      margin-left: 10px;
      margin-right: 20px;
      color: $blue;
      &.btn {
        color: $blue;
        margin-left: 30px;
        font-size: 0.95em;
        border-color: $blue;
        &:hover{
          color: $primary;
        }
      }
    }
    .pop-menu {
      border-radius: 6px;
      a {
        display: block;
        white-space: normal;
        margin: 5px 0;
        text-decoration: none;
        padding: 12px 15px 5px 20px;
        &:hover {
          background: lighten($secondary-text-color, 50%);
        }

      }
    }
  }
  amp-sidebar {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    a {
      font-size: 1em;
      padding: 10px 30px 10px 40px;
      color: $link_color;
      &:hover{
        text-decoration: none;
        background-color: $hover-color;
      }
    }
  }
  @media (min-width: 840px) {
    margin-top: 0;
    width: 100%;

    nav {
      display: flex;
      align-items: center;

    }
    .section-heading {
      background-color: initial;
      padding: 0;
      position: inherit;
      box-shadow: none;
      a {
        &.login {
          display: none;
        }
        margin: 10px 16px;
      }
    }
  }
}
.mob-menu-toggle{
  cursor: pointer;
}
.mobile-right-nav{
  display: flex;
  align-items: center;
  min-width: 250px;
  justify-content: flex-end;
}

.a1 {
  font-size: 0.875em;
  color: $primary-text-color;
  margin: 5px 0;
}
.a2 {
  font-size: 0.75em;
  line-height: 1.5;
  color: $secondary-text-color;
}
