footer{
  padding: 30px 1px ;
  background: rgb(22,35,99);
background: linear-gradient(0deg, rgba(22,35,99,1) 0%, rgba(16,69,135,1) 100%);
  text-align: center;
  color: white;
  overflow: hidden;
  .column {
    overflow: auto;
  }
  .title{
    font-size: .9em;
    margin-right: 10px;
  }
  .badges{
    margin: 10px 0 20px;
    a{
      display: inline-block;
      &.apple{
        margin-top: -20px;
      }
    }
  }
  .title {
    font-size: 1.3em;
  }
  .links{
    max-width: 360px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
    a{
      margin:  0;
      white-space: nowrap;
      margin-left: 6px;
      text-decoration: underline;
      color: white;
      font-size: .9em;
    }
  }
  .fcta{
    margin: 30px auto;
    text-align: center;
    a{
      color: white;
    }
  }
  @media (min-width: 840px) {
    padding: 50px 0 150px;
    text-align: left;
    .title{
      font-size: 1.3em;
      margin-right: initial;
    }
    .badges{
      margin-top: 33px;
      a{
        display: inline-block;
        &.apple{
          margin-top: -20px;
        }
      }
    }
    .links{
      width: 40%;
      display: block;
      margin: 0 auto;
      a{
        margin:  4px 0;
        padding: 4px 0;
        text-decoration: none;
        color: white;
        font-size: 1em;
        display: block;
      }
    }
    .fcta{
      text-align: center;
      a{
        color: white;
      }
    }
  }
}
