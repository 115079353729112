.top-features{
  background: rgb(22,35,99);
  background: radial-gradient(circle, rgba(22,35,99,1) 0%, rgba(16,69,135,1) 100%);
  clip-path: polygon(0 2%, 100% 0, 100% 98%, 0% 100%);

  color: white;
  padding: 200px 0 100px;
  text-align: center;
  .h2{
    color: white;
  }
  .top-headings{
    margin: 20px auto 70px;
  }
}

.features{
  amp-img{
    max-width: 50px;
    margin: 0 auto 30px;
  }
  font-size: 1.1em;
  .column{
    margin-bottom: 60px;
    p{
      width: 80%;
      margin: 10px auto;
    }
  }
}
@media (min-width: 840px) {
  .top-features{
    clip-path: polygon(30% 11%, 69% 4%, 100% 0, 100% 94%, 69% 96%, 30% 98%, 0 100%, 0 17%);
  }
  .top-headings{
    max-width: 540px;
    font-size: initial;
    margin: 20px auto 50px;
  }
}

