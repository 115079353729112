.customer_types{
  margin: 10px 0;
  padding: 20px;
  text-align: center;
  .about{
    min-height: 80px;
  }

  .top-headings{
    font-size: 0.85em;
    padding-bottom: 30px;
  }
  .column{
    margin: 20px 0 63px 0;
    font-size: 0.75em;
    padding: 20px 40px;
    .title {
      white-space: nowrap;
    }
  }
  .actor{
    max-height: 180px;
  }
  .divider-vert {
    display: none;
  }
  @media (min-width: 600px)  {
    .divider-vert {
      display: block;
    }
    .column{
      padding: initial;
    }
  }
  @media (min-width: 840px) {
    margin: 120px 0;
    text-align: center;
    .top-headings{
      max-width: 540px;
      font-size: initial;
      margin: 20px auto 50px;
    }
    .about{
      min-height: 100px;
    }
    .column{
      margin: 0 40px;
      font-size: 80%;
    }
    .actor{
      max-height: 280px;
    }
  }
}
