.pricing-container {
  margin: 30px auto;
  text-align: center;
}
.pricing-top {
  margin: 120px auto 40px;
  h1 {
    margin: 20px 0;
    color: $black;
    line-height: 1.25;
  }
  h2 {
    margin: 10px 0;
    font-size: 1.1em;
    color: $secondary-text-color;
  }
}

.price-tag {
  font-size: 1.5em;
  .euro {
    color: $secondary-text-color;
    font-size: 0.6em;
    margin-left: 5px;
    vertical-align: middle;
  }
}
.block{
  .price-tag {
    justify-content: flex-end;
  }
}

.price-tag {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addon{
  width: 100%;
  padding: 15px;
  margin: 30px auto;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  .title{
    font-size: 1.2em;
    text-align: left;
  }
  .secondary{
    font-size: 0.8em;
  }
  .text{
    text-align: left;
  }
}
@media (min-width: 960px) {
  .align-right{
    text-align: right;
  }
}
.pricing-table {
  background: #fff;
  width: 100%;
  margin: 0 auto 20px;
  border-collapse: collapse;
  padding: 15px;
  font-size: 1em;
  tr{
    &.plan{
      font-size: 0.9em;
      background: #f5f5f5;
    }
    &:hover{
      background: #f5f5f5;
    }
  }
  td, th{
    padding: 10px;
    &.attr{
      color: $secondary-text-color;
      font-size: 0.9em;
      text-align: left;
    }

  }
  tbody{
    tr{
      border-top: 1px solid #c0c0c0;
    }
  }
}
.pricing-tab {
  max-width: 350px;
  margin: 30px auto;
  a {
    cursor: pointer;
  }
}
.secondary {
  color: $secondary-text-color;
}


.faq {
  margin: 50px auto;
  .h1 {
    border-top: 1px solid $border-color;
    padding-top: 50px;
    display: inline-block;
  }
}
.question {
  margin: 40px 0;
  font-size: 110%;
  .h2 {
    font-size: 1.1em;
    margin: 5px 0;
    font-weight: bold;
    color: $primary-text-color;
  }
  p {
    margin: 5px 0;
    line-height: 1.6em;
  }
}

.check{
  background: url('/asset/image/check.svg') no-repeat center center;
}

@media (min-width: 960px) {
  .pricing-features {
    ul{
      margin: 20px auto 0;
    }
    ol{
      margin: 45px auto 0;
    }
    ul,
    ol {
      width: 60%;
      li {
        span {
          border-bottom: 1px dashed $border-color;
          cursor: help;
        }
      }
    }
  }
}

.calculator {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

.calculator{
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto 50px;
  width: 100%;
  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2{
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
    color: $primary;
  }
  label {
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: flex-end;
    margin: 10px auto;
    .text{
     margin-left: 8px;
     color: $secondary-text-color;
    }
   }
}



.value{
  font-size: 2em;
  font-weight: bold;
  color: $primary;
}

input[type="range"] {
  width: 100%;
  height: 26px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $border-color;
  border-radius: 10px;
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: $primary;
  border-radius: 50%;
  cursor: pointer;
}

span {
  font-size: 18px;
  margin-left: 10px;
  font-weight: bold;
}

