amp-consent{
  &.tbConsent{
    max-width: 350px;
    padding: 15px;
    font-size: 0.7em;
    opacity: 1;
    background: white;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 0 8px rgba(110, 109, 109, 0.6);
    left: 30px;
    bottom: 30px;
    .action-2{
      height: 35px;
      font-size: 110%;
      line-height: 18px;
    }
    .close-btn{
      cursor: pointer;
    }
  }
}
