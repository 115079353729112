


body{
  font-family: 'Hind Siliguri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-text-color;
  font-size: 0.9em;
  line-height: 1.5;
}

.h1{
  font-size: 2.2em;
  line-height: 1.45;
}

.h2{
  font-size: 1.35em;
  line-height: 1.6;
  color: $secondary-text-color;
}

ul{
  list-style: none;
  padding: 0;
  margin: 20px 0;
}
li{
  padding-left: 40px;
  margin: 15px auto;
  &:before{
    position: absolute;
    content: " ";
    background: url('/asset/image/check.svg') no-repeat 0 0;
    background-size: 90%;
    width: 24px;
    height: 24px;
    margin-left: -40px;
    padding-top: 20px;
    margin-top: 2px;
  }
}
@media(max-width: 400px){
  .pricing-container, .feature{
    padding:0;
  }
}

.fs-img{
  border-radius: 5px;
  box-shadow: 0 8px 32px rgba(155,162,189,0.5);
  background: white;
}


.feature-matrix-item{
  border-radius: 8px;
  margin: 0 15px;
  .h1{
    font-size: 1.4em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .content{
    p{
      color: $secondary-text-color;
      margin: 8px 0;
      font-size: 1em;
    }
  }
}
