$baseUrl: "https://topbroker.lt/";
$black: #20202a;
$primary-text-color: #212529;
$secondary-text-color: lighten($primary-text-color, 30%);
$primary: #104587;
$link_color: $primary;
$border-color: rgba(204,204,204,0.75);
$hover-color: lighten($border-color, 10%);


@import 'framework';
@import 'base.scss';
@import '_component/header/header.scss';
@import '_component/custom_types/custom_types.scss';
@import '_component/index/index.scss';
@import '_component/footer/footer.scss';
@import '_component/features/features.scss';
@import 'privatumo-politika/index.scss';
@import 'crm-kaina/kainos.scss';
@import '404/404.scss';
@import '_component/shared/cta-button.scss';
@import '_component/logos/logos.scss';
@import '_component/cookie/cookie.scss';
@import '_component/kontaktai/kontaktai.scss';
@import '_component/top-features/top-features.scss';
@import '_component/about/about.scss';
@import '_component/feature-set/feature-set.scss';
@import '_component/testimonials/testimonials.scss';
