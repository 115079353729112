.index-top-cta{
  margin-top: 30px;
  margin-bottom: 0;
  padding: 30px 30px 0 30px;

  h1{
    font-size: 2.6em;
    margin: 20px 0;
    color: $black;
    line-height: 1.25;
  }
  h2{
    margin: 20px 0;
    font-size: 1.35em;
  }
  .reg-btn{
    margin-top: 40px;
  }
  ul{
    margin: 0 0 0 5px;
    color: $secondary-text-color;
  }
  @media (min-width: 840px) {
    margin: 80px auto;
    padding: 30px;
  }
  @media (max-width: 600px) {
    h1{
      font-size: 2.4em;
    }
  }
}
