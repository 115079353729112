.test {
  box-direction: normal;
}
.vyst-nt-tip1{
  top: 19%;
}
.vyst-nt-tip2{
  top: 59%;
}


.vyst-nt-tip3{
  top: 68%;
}

.vyst-nt-tip4{
  top: 85%;
}

.vyst-kn-tip1{
  top: 10%;
}

.vyst-kn-tip2{
  top: 54%;
}

.vyst-kn-tip3{
  top: 88%;
}
