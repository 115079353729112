
.adm-nt-tip1{
  top: 41%;
}

.adm-nt-tip2{
  top: 64%;
}

.adm-nt-tip3{
  top: 91%;
}

.admin-kn-tip0{
  top: 8%;
}
.admin-kn-tip1{
  top: 32%;
}
.admin-kn-tip2{
  top: 74%;
}
