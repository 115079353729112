

.testimonials{
  margin: 10px 0;
  padding: 20px;
  text-align: center;
  .review{
    font-style: italic;
    color: #333;
    text-align: left;
    font-size: 1em;
  }
  .company{
    font-size: 0.9em;
    color: #666;
  }
  .author{
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 30px;
  }

  .column{
    margin: 20px 0 63px 0;
    padding: 20px 25px;
    min-width: 260px;

    .title {
      white-space: nowrap;
    }
  }
  .divider-vert {
    display: none;
  }
  @media (min-width: 600px)  {
    .divider-vert {
      display: block;
    }
    .column{
      padding: initial;
    }
  }
  @media (min-width: 840px) {
    margin: 120px 0;
    text-align: center;
    .top-headings{
      max-width: 540px;
      font-size: initial;
      margin: 20px auto 50px;
    }
    .column{
      margin: 0 40px;
      max-width: 350px;
    }
  }
  @media (min-width: 1260px) {
    .review{
      min-height: 400px;
    }
  }
}
