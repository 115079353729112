.logos{
  padding: 60px 30px;
  background: lighten($border-color, 10%);
  .h1{
    padding: 50px 0;
  }
  amp-base-carousel {
    touch-action: none;
    pointer-events: none;
  }
}
