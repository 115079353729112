@import "./nt-vystytojams/nt-vystytojams.scss";
@import "./nt-administratoriams/nt-administratoriams.scss";
@import "./nt-agenturoms/nt-agenturoms.scss";

.rounded{
  border-radius: 8px;
}
.bordered{
  border: 1px solid #f0f0f0;
}

.feature-image{
  @extend .rounded;
  background-color: white;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 1);
  margin: 0;
  max-width: 500px;
}

.feature {
  max-width: 480px;
  padding: 50px 30px;
  .image-column {
    &:hover {
      .ring-container {
        visibility: visible;
      }
    }
  }
  .zoom-image {
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.4);
    }
  }
  amp-img {
    @extend .feature-image;
  }

  @media (min-width: 600px)  {
    max-width: 580px;
    padding: 50px 18px;
    amp-img {
      max-width: 100%;
    }
  }
  @media (min-width: 840px) {
    max-width: 970px;
    margin: 150px auto;
    amp-img {
      max-width: 500px;
    }

    .image-column {
      &:hover {
        .ring-container {
          visibility: visible;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
}

.ring-container {
  position: relative;
  cursor: pointer;
  z-index: 996;
  @media (min-width: 840px) {
    visibility: hidden;

  }
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #1872e7;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
}

.ringring {
  border: 3px solid #1872e7;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
  top: 15px;
  animation: pulsate 1.5s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
