.about-crm{
  background: rgb(22,35,99);
  background: radial-gradient(circle, rgba(22,35,99,1) 0%, rgba(16,69,135,1) 100%);
  clip-path: polygon(0 2%, 100% 0, 100% 98%, 0% 100%);

  color: white;
  padding: 200px 0 100px;
  text-align: center;
  .h2{
    color: white;
  }
  .top-headings{
    margin: 20px auto 70px;
  }
}


@media (min-width: 840px) {
  .about-crm{
    clip-path: polygon(49% 11%, 87% 17%, 100% 22%, 100% 100%, 68% 100%, 35% 100%, 0 100%, 0% 43%, 0 0);
  }
}
