.not_found{
  margin: 50px auto;
  text-align: center;
  border-radius: 8px;
  background: #f0f0f0;
  padding: 70px;
  h1{
    font-size: 1.5em;
  }
}
