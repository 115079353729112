.top-headings{
  margin: 70px auto;
  text-align: center;
}
.icon{
  background: #f0f0f0;
  padding: 15px;
  border-radius: 15px;
  height: 80px;
  width: 80px;
  text-align: center;
}
.feature-block{
  display: grid;
  grid-template-columns: 110px auto 100px 100px;
  border: 1px solid #c0c0c0;
  margin: 15px;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  color: $primary-text-color;
  .title{
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
  }
  .story{
    color: $secondary-text-color;
  }
  &:hover{
    color: $primary-text-color;
    text-decoration: none;
    border-color: #c0c0c0;
    cursor: pointer;
    .more{
      visibility: visible;
      color: $secondary-text-color;
    }
  }
  .more{
    visibility: hidden;
  }
}
.is-new, .is-renew{
  text-align: left;
  padding: 0 15px;
  span{
    color: red;
    border: 1px solid red;
    font-size: 0.6em;
    padding: 2px 10px;
    border-radius: 6px;
    cursor: help;
  }
}
.is-renew{
  span{
    color: green;
    border-color: green;
  }
}
.more{
  text-align: center;
  align-self: center;
  span{
    font-weight: bold;
  }
}

@media (max-width: 600px){
  .feature-block{
    grid-template-columns: 110px auto;
  }
}

.arrow {
  border: solid #666;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
