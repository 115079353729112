#modal {
  border-radius: 8px;
}
.modal {
  border-radius: 8px;
  &:target {
    z-index: 1001;
  }
  .modal-container {
    max-height: 95vh;
    border-radius: 8px;
    padding: 0;

    .modal-header {
      border-radius: 8px;
      color: #fff;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .8rem;
    }
    .modal-footer{
      border-top: 1px solid $primary;
    }
  }
}
#input-user-count{
  max-width: 100px;
}
.form-group{
  .form-input{
    border-radius: 8px;
  }
  textarea{
    min-height: 50px;
  }
  h6{
    font-size: 0.9em;
  }
  label{
    font-size: 0.8em;
    &.form-label{
      padding-bottom: 0;
    }
  }
  &.required{
    label{
      &:after{
        content:" *";
        color: red;
      }
    }
  }
}

.form-checkbox input:checked+.form-icon, .form-radio input:checked+.form-icon, .form-switch input:checked+.form-icon {
  background: $primary;
}

.free{
  display: block;
  margin: 10px;
  color: lighten($secondary-text-color, 2%);
  font-size: 0.85em;
}

.success, .failure{
  width: 100%;
  display: inline-block;
  text-align: left;
  font-size: 1em;
  padding: 10px;
}
.success{
  color: #270;
}
.cta-success{
  background-color: #1cb841;
  color: white;
  padding: 0 100px;
  font-size: 1.2em;
}
.green{
  display: block;
  color: #1cb841;
  margin: 10px;
  font-size: 0.9em;
}
