
.agent-nt-tip1{
  top: 5%;
}

.agent-nt-tip2{
  top: 34%;
}

.agent-nt-tip3{
  top: 61%;
}

.agent-nt-tip4{
  top: 88%;
}
.agent-kn-tip0{
  top: 3%;
}

.agent-kn-tip1{
  top: 13%;
}
.agent-kn-tip2{
  top: 50%;
}
.agent-kn-tip3{
  top: 91%;
}
